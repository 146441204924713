import React from "react";
import gerb from "../../../assets/images/gerb.png";
import karta from "../../../assets/images/karta.png";
import ScrollToTop from "./scrollToTop/ScrollToTop";
import { FiInstagram, FiYoutube } from "react-icons/fi";
import { FaTelegramPlane } from "react-icons/fa";
import { BsFacebook } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="lg:h-[400px] md:h-[400px] bg-[#251749] text-white">
      <div className="max-w-[1280px] mx-auto lg:flex md:flex justify-between pt-4 lg:px-0 md:px-3 px-4">
        <div className="lg:w-[650px] md:w-[600px] flex flex-col gap-4 lg:text-[17px] md:text-[16px]">
          <div className="flex gap-2 items-center pt-[6px]">
            <img src={gerb} alt="gerb" width="50px" height="50px" />
            <h2 className="text-xl font-semibold text-white">
              {t("O'rta Chirchiq tumani hokimligi")}
            </h2>
          </div>
          <p>
            {t(
              "Sayt Toshkent viloyati hokimligi huzuridagi, Axborot kommunikatsiya texnologiyalarini rivojlantirish markazi mutaxassislari tomonidan yaratilgan © 2023"
            )}
          </p>
          <p>
            {t(
              "Ushbu sayt materiallaridan foydalanganda www.o'rtachirchiq.uz saytiga havola qilish majburiydir"
            )}
          </p>
          <a className="hover:text-[#F97B22] duration-300" href="/saytxaritasi">
            {t("Sayt xaritasi")}
          </a>
          <div className="flex gap-6 ">
            <a
              className="hover:text-[#F97B22] duration-300"
              href="https://www.instagram.com/admin.hokimyat/"
              target="_blank"
            >
              <FiInstagram size={26} />
            </a>
            <a
              className="hover:text-[#F97B22] duration-300"
              href="https://www.youtube.com/channel/UCjpn7-G39nyd-Q7jr-1uIVw"
              target="_blank"
            >
              <FiYoutube size={29} />
            </a>
            <a
              className="hover:text-[#F97B22] duration-300"
              href="https://t.me/urta_chirchiq_hokimligi"
              target="_blank"
            >
              <FaTelegramPlane size={28} />
            </a>
            <a
              className="hover:text-[#F97B22] duration-300"
              href="https://www.facebook.com/ortachirchiq.hokimyati"
              target="_blank"
            >
              <BsFacebook size={26} />
            </a>
          </div>
        </div>
        <div className="flex flex-col gap-2 mt-[10px]">
          <p>{t("Bizning manzil:")}</p>
          <p>{t("O'rta Chirchiq tumani, Uyg'ur MFY, Yoshlik ko'ch, 1-uy")}</p>
          <a
            href="https://www.google.com/maps/place/41%C2%B004'12.0%22N+69%C2%B016'22.5%22E/@41.0721796,69.2747935,14.96z/data=!4m4!3m3!8m2!3d41.07!4d69.2729167"
            target="_blank"
            className="overflow-hidden bg-no-repeat bg-cover"
          >
            <img
              src={karta}
              alt="harita"
              width="400px"
              height="400px"
              className="hover:scale-125 object-cover transition duration-700 ease-in-out"
            />
          </a>
          <p>
            <a href="tel:+998707629530">{t("Tel: +998707629530")}</a>
            <br />
            <a href="tel:+998707629526">{t("Tel:  +998707629526")}</a>
          </p>
          <p>{t("Murojaat qilish vaqti: 9:00/18:00")}</p>
        </div>
      </div>
      <ScrollToTop />
    </div>
  );
};

export default Footer;
