import { useParams } from "react-router-dom";
import { useGetNavDetail } from "../../../../hooks/query";
import NavbarDetailItem from "./NavbarDetailItem";
import { useNavigate } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { useTranslation } from "react-i18next";

const NavbarDetail = () => {
  const { navid } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const useGetNavDetailList = useGetNavDetail({
    id: navid,
  });

  return (
    <div>
      <button
        onClick={() => navigate(-1)}
        className="lg:flex md:flex hidden items-center gap-1 bg-[#01497c] text-white pr-4 px-3 rounded py-1 hover:bg-[#61a5c2] transition-all duration-200 mb-8 lg:ml-0 md:ml-4"
      >
        <TiArrowBack size={26} />
        {t("ortga")}
      </button>
      {useGetNavDetailList.data?.results.map((item) => {
        return <NavbarDetailItem item={item} key={item.id} />;
      })}
      {useGetNavDetailList.data?.results == 0 && (
        <p className="text-center text-red-500 my-[200px] font-semibold text-2xl">
          {t("Ma'lumot mavjud emas")}
        </p>
      )}
    </div>
  );
};
export default NavbarDetail;
